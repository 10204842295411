<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <a-button v-action:add type="primary" icon="plus" @click="handleAdd">新增采购</a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="procure_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
    >
      <div slot="procure_tool_list" slot-scope="text, item">
        <div v-for="i2 in text">{{ i2.tool_name }} x {{ i2.number }}</div>
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag v-if="text===0">待审核</a-tag>
        <a-tag color="green" v-if="text===1">审核通过</a-tag>
        <div v-if="text===2">
          <a-tag color="red">审核驳回</a-tag>
          <br>
          <a style="color: red">{{ item.remarks }}</a>
        </div>
      </div>

      <div class="actions" slot="action" slot-scope="text, item">
        <template v-if="item.status==0">
          <a v-action:audit @click="handleAudit(item,1)">审核通过</a>
          <a v-action:audit @click="handleAudit(item,2)" style="color: red">审核驳回</a>
        </template>
        <a v-action:delete @click="handleDelete(item)">删除</a>
      </div>
    </s-table>
    <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
    <AuditForm ref="AuditForm" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/procure/index'
import {STable} from '@/components'
import AuditForm from './modules/AuditForm.vue'
import SaveForm from './modules/SaveForm'
import SearchForm from './modules/SearchForm'

export default {
  name: 'Index',
  components: {
    STable,
    AuditForm,
    SaveForm,
    SearchForm
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'procure_id'
        // },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '采购物品',
          dataIndex: 'procure_tool_list',
          scopedSlots: {customRender: 'procure_tool_list'}
        },
        // {
        //   title: '采购地址',
        //   dataIndex: 'procure_address',
        // },
        {
          title: '采购时间',
          dataIndex: 'procure_time',
        },
        {
          title: '采购人',
          dataIndex: 'procure_storeuser.real_name',
        },
        {
          title: '审核人',
          dataIndex: 'audit_storeuser.real_name',
        },
        // {
        //   title: '添加时间',
        //   dataIndex: 'create_time'
        // },
        {
          title: '操作',
          dataIndex: 'action',
          width: '100px',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {

    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },
    handleAudit(item, status) {
      if (status == 2) {
        this.$refs.AuditForm.edit(item, status)
        return
      }
      Api.edit({procure_id: item['procure_id'], form: {status, audit: 1}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },
    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({procure_id: item['procure_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
