<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">

      <div style="display: flex;justify-content: space-between">
        <span>未找到采购的物品？<a @click="addTool">前往新增工具</a></span>
        <a-button style="margin-bottom: 10px" type="primary" size="small" @click="handleAdd">增加一项</a-button>
      </div>
      <table class="table" border="1">
        <tr class="table-title">
          <td>采购物品</td>
          <td>单价</td>
          <td>采购数量</td>
          <td>采购地点</td>
          <td>操作</td>
        </tr>

        <tr v-for="(item,index) in toolTableList">
          <td style="width: 260px">
            <a-select
                @change="setToolName($event,index)"
                style="width: 260px"
                allowClear
                show-search
                :filter-option="filterOption"
                v-model="item.tool_id"
                placeholder="请选择采购物品"
            >
              <a-select-option v-for="item in toolList" :value="item.tool_id" :item="item">
                {{ item.tool_name }} (<span style="color: red">¥</span>{{ item.price }}）
              </a-select-option>
            </a-select>
          </td>
          <td>
            <span style="color: red">￥</span>
            <a-input-number
                style="width: 160px"
                placeholder="请输入单价"
                v-model="item.price"
            />
            元
          </td>
          <td>
            <a-input-number
                style="width: 100%"
                placeholder="请输入采购数量"
                v-model="item.number"
            />
          </td>
          <td>
            <a-input
                placeholder="请输入采购地点"
                v-model="item.procure_address"
            />
          </td>
          <td>
            <a @click="handleDel(index)" style="color: red">删除</a>
          </td>
        </tr>

      </table>

      <!--      <a-form :form="form">-->


      <!--        <a-form-item label="采购物品" :labelCol="labelCol" :wrapperCol="wrapperCol">-->

      <!--          <a-select-->
      <!--              allowClear-->
      <!--              mode="multiple"-->
      <!--              @change="rendToolTabel"-->
      <!--              show-search-->
      <!--              :filter-option="filterOption"-->
      <!--              v-decorator="['tool_ids',{rules: [{required: true, message: '请选择采购物品'}]}]"-->
      <!--              placeholder="请选择采购物品"-->
      <!--          >-->
      <!--            <a-select-option v-for="item in toolList" :value="item.tool_id" :item="item">-->
      <!--              {{ item.tool_name }} (<span style="color: red">¥</span>{{ item.price }}）-->
      <!--            </a-select-option>-->
      <!--          </a-select>-->

      <!--          <div class="table" v-if="!isEmpty(form.getFieldValue('tool_ids'))">-->
      <!--            <div class="tr">-->
      <!--              <div class="td">物品名称</div>-->
      <!--              <div class="td">单价</div>-->
      <!--              <div class="td">采购数量</div>-->
      <!--            </div>-->
      <!--            <div class="tr" v-for="item in toolTableList">-->
      <!--              <div class="td">{{ item.tool_name }}</div>-->
      <!--              <div class="td">-->
      <!--                <a-input v-model="item.price"/>-->
      <!--              </div>-->
      <!--              <div class="td">-->
      <!--                <a-input v-model="item.number"/>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--        </a-form-item>-->
      <!--        <a-form-item label="采购地点" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
      <!--          <a-input-->
      <!--              placeholder="请输入采购地点"-->
      <!--              v-decorator="['procure_address', {rules: [{required: true, message: '请输入采购地点'}]}]"-->
      <!--          />-->
      <!--        </a-form-item>-->

      <!--        <a-form-item label="采购时间" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
      <!--          <a-date-picker-->
      <!--              style="width:100%"-->
      <!--              allowClear-->
      <!--              show-time-->
      <!--              format="YYYY-MM-DD HH:mm:ss"-->
      <!--              v-decorator="['procure_time',{rules: [{required: true, message: '请选择采购时间'}]}]"-->
      <!--          />-->
      <!--        </a-form-item>-->
      <!--      </a-form>-->
    </a-spin>
    <saveToolForm
        ref="saveToolForm"
        @handleSubmit="getToolAll"
    />
  </a-modal>
</template>

<script>
import * as Api from '@/api/procure'
import * as toolApi from '@/api/tool'
import _ from "lodash";
import {isEmpty} from "@/utils/util";
import saveToolForm from '../../tool/toolList/modules/SaveForm'

export default {
  components: {
    saveToolForm
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      toolList: [],
      toolTableList: [
        {
          tool_id: undefined,
          tool_name: '',
          price: '',
          number: '',
          procure_address: ''
        }
      ]
    }
  },
  methods: {
    addTool() {
      this.$refs.saveToolForm.add()
    },
    isEmpty,
    handleAdd() {
      this.toolTableList.push({
        tool_id: undefined,
        tool_name: '',
        price: '',
        number: '',
        procure_address: ''
      })
    },
    handleDel(index) {
      if (this.toolTableList.length <= 1) {
        this.$message.error('至少保留一条记录！')
        return false
      }
      this.toolTableList.splice(index, 1)
    },
    async getToolAll() {
      this.confirmLoading = true
      const {data: {list}} = await toolApi.selectList({tool_type: 0})
      this.toolList = list
      this.confirmLoading = false
    },

    setToolName(e, index) {
      const {tool_name, price} = this.toolList.find(item => item.tool_id === e)
      this.toolTableList[index].tool_name = tool_name
      this.toolTableList[index].price = price
    },

// 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    /**
     * 显示对话框
     */
    add() {
      this.getToolAll()
      this.mode = "add"
      this.title = '新增采购'
      this.visible = true
    },
    edit(record) {
      this.getToolAll()
      this.mode = "edit"
      this.title = '编辑采购'
      this.visible = true
      this.record = record
      this.setFieldsValue()
    },
    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['tool_ids', 'procure_address', 'procure_time'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const errorItem = this.toolTableList.filter(item => {
        return isEmpty(item.price) || isEmpty(item.tool_id) || isEmpty(item.tool_name) || isEmpty(item.procure_address) || isEmpty(item.number)
      })
      if (!isEmpty(errorItem)) {
        this.$message.error('请完善表格信息！')
        return false
      }
      this.onFormSubmit()
      // const {form: {validateFields}} = this
      // // 表单验证
      // validateFields((errors, values) => {
      //   // 提交到后端api
      //   !errors && this.onFormSubmit(values)
      // })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit() {
      this.confirmLoading = true
      var sendApi = null
      var values = {}
      if (this.mode == "add") {
        values.toolTableList = this.toolTableList
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({procure_id: this.record['procure_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  text-align: center;
  width: 100%;
  border-color: #e8e8e8;

  .table-title {
    font-weight: bold;

    td {
      padding: 10px;
    }
  }
}

.loadUnload {
  margin-top: 10px;
  border: 1px #00a0e9 dashed;
  padding: 10px;
  background: #f9f9f9;
}
</style>
